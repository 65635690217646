import React from 'react'
import { BsFillCameraVideoFill, BsFillStarFill, BsMic, BsMusicNoteBeamed, BsTicketFill } from 'react-icons/bs'
import { Berlin, Nelson, Vally } from '../../assets/images'

const BestSection = (props) => {
    return (
        <section className={'bestSection'} id={props.id}>
            <aside>
                <h2 className='animate__animated animate__fadeIn'>About Us</h2>
                <p className='animate__animated animate__fadeIn'>
                    Minnesota was founded by Leonard Warner in 2023 and it is one amongst the top leading Record label in the East.
                    The roster of artists includes; Vally, Nelson-jay and Jerry Berlin. Also our producer; Oxygen by name. While that of the management includes; Brytt, known to be the personal adviser(PA).
                    Michy, known as the marketing manager. Charles known as Contract administrator and Kammy as the secretary in the industry.
                    As Times continues to change, Minnesota has been able to stay innovative and expansive to remain at the forefront of the record
                    labels in this part of the world with the service rendered by the dedicated array of committed individuals overseeing the affairs.
                </p>
                <ul className={'flexRow alignCenter'}>
                    <li className={'flexRow alginCenter animate__animated animate__fadeInUp animate__delay-1s'}>
                        <BsMusicNoteBeamed />
                        Music Licensing
                    </li>
                    <li className={'flexRow alginCenter animate__animated animate__fadeInUp animate__delay-2s'}>
                        <BsFillCameraVideoFill />
                        Content Production
                    </li>
                    <li className={'flexRow alginCenter animate__animated animate__fadeInUp animate__delay-3s'}>
                        <BsFillStarFill />
                        Brand Partnerships
                    </li>
                    <li className={'flexRow alginCenter animate__animated animate__fadeInUp animate__delay-4s'}>
                        <BsMic />
                        Performance and Appearance
                    </li>
                    <li className={'flexRow alginCenter animate__animated animate__fadeInUp animate__delay-5s'}>
                        <BsTicketFill />
                        Ticket Sales
                    </li>
                </ul>
            </aside>
            <aside className={'flexRow alignCenter'} style={{ gap: '10px' }}>
                <div>
                    <img src={Berlin} alt='poe' className='animate__animated animate__fadeInUp animate__delay-1s' />
                    <img src={Nelson} alt='magic' className='animate__animated animate__fadeInUp animate__delay-2s' />
                </div>
                <div>
                    <img src={Vally} alt='cray' className='animate__animated animate__fadeInUp animate__delay-3s' />
                    {/* <img src={Boy} alt='boy' className='animate__animated animate__fadeInUp animate__delay-4s' /> */}
                </div>
            </aside>
        </section>
    )
}

export default BestSection
import React from 'react'
import {  BsPlayFill } from 'react-icons/bs'
import { Doosin, Hustle, Vibration } from '../../assets/images'

const Singles = (props) => {

    return (
        <section id={props.id} className={'singlesSection'}>
            <header className={'flexRow justifyBetween flexWrap'} style={{ gap: '20px' }}>
                <div>
                    <h2>Our Latest Singles</h2>
                    <p>Check out our recommended playlist, let's put the Zest in your life</p>
                </div>
                {/* <button className={'flexRow alignCenter'}>
                    View all
                    <BsArrowRight size={28} />
                </button> */}
            </header>
            <picture className={'imageRowView'}>
                <div>
                    <img src={Vibration} alt='poe' />
                    <ul>
                        <li>
                            <button
                                className={'flexRow alignCenter'}
                                onClick={() => window.open('https://vally.fanlink.to/Vibration', '_blank', 'noopener')}
                            >
                                Play
                                <BsPlayFill size={30} />
                            </button>
                        </li>

                    </ul>
                </div>
                <div>
                    <img src={Doosin} alt='poe' />
                    <ul>
                        <li>
                            <button
                                className={'flexRow alignCenter'}
                                onClick={() => window.open('https://nelsonjay.fanlink.to/Doosin', '_blank', 'noopener')}
                            >
                                Play
                                <BsPlayFill size={30} />
                            </button>
                        </li>
                    </ul>
                </div>
                <div>
                    <img src={Hustle} alt='poe' />
                    <ul>
                        <li>
                            <button className={'flexRow alignCenter'}
                                onClick={() => window.open('https://jerryberlin.fanlink.to/Hustle', '_blank', 'noopener')}
                            >
                                Play
                                <BsPlayFill size={30} />
                            </button>
                        </li>

                    </ul>
                </div>
            </picture>
        </section>
    )
}

export default Singles
import React from 'react';
import { BsArrowDown,BsPlayFill } from 'react-icons/bs';
// import { useNavigate } from 'react-router-dom';
import { Link,  } from "react-scroll";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper/modules';
import { Berlin, Nelson, Vally } from '../../assets/images';


const HeroSection = () => {

  // const navigate = useNavigate()
  // const goToAbout = () => {
  //   navigate('/aboutUs')
  // }
  const playButton = () => {
    window.open('https://www.youtube.com/@sota.records', '_blank', 'noopener');
  }
  // 
  return (
    <section className={'heroSection'} id='hero'>
      <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[EffectFade, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={Berlin} alt='artist' style={{ width: '100%', height: '100vh', objectFit: "cover", objectPosition: 'top' }} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Vally} alt='artist' style={{ width: '100%', height: '100vh', objectFit: "cover", objectPosition: 'top' }} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Nelson} alt='artist' style={{ width: '100%', height: '100vh', objectFit: "cover", objectPosition: 'top' }} />
        </SwiperSlide>
      </Swiper>
      <div className={'heroOverlay'}>
        <header className={'animate__animated animate__fadeIn animate__delay-0.5s'}>
          <h1>MINNESOTA RECORDS</h1>
          <h2>Amplifying Dreams, Elevating Music</h2>
          <div className={'flexRow alignCenter'}>
            <button className={'flexRow alignCenter'} onClick={playButton}>
              Play
              <BsPlayFill size={30} />
            </button>
            {/* <button className={'flexRow alignCenter'} onClick={goToAbout}>
              Learn more
              <BsArrowRight size={30} />
            </button> */}
          </div>
        </header>

        <div className={'nextScroll'}>
          <Link
            activeClass="active"
            to="section1"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500} >
            <BsArrowDown size={100} className={' animate__animated animate__bounce animate__infinite animate__slow'} />
          </Link>
        </div>


      </div>
    </section>
  )
}

export default HeroSection
import React from 'react'
import Navbar from '../../widgets/Navbar'
import { Hustling, Interview, Listening, Vibz } from '../../assets/images'

const Blogs = () => {
    return (
        <>
            <Navbar />
            <section className={'blogSection'}>
                <header>
                    <h2>Blogs</h2>
                </header>
                <div className={'blogsContentContainer'}>
                    <figure className={'largeBlogCard animate__animated animate__fadeIn'}>
                        <img src={Listening} alt='listening' />
                        <figcaption>
                            <div className={'smallCardTop'}>
                                <p className={'bgHolder'}>Minnesota records</p>
                                <p>5 min read</p>
                            </div>
                            <h4>Minnesota unveiling and listening party </h4>
                            <div className={'smallCardBtm'}>
                                <div className={'mBackground'}>
                                    <p>M</p>
                                </div>
                                <div>
                                    <p>Minnesota</p>
                                    <p>30th September 2023</p>
                                </div>
                            </div>
                        </figcaption>
                    </figure>
                    <ul>
                        <li className={'animate__animated animate__fadeIn animate__delay-1s'}>
                            <figure className={'smallBlogCard'}>
                                <img src={Vibz} alt='listening' />
                                <figcaption>
                                    <div className={'smallCardTop'}>
                                        <p className={'bgHolder'}>Minnesota records</p>
                                        <p>5 min read</p>
                                    </div>
                                    <h4>Vally dropped a hot single Vibration, and it's a vibe</h4>
                                    <div className={'smallCardBtm'}>
                                        <div className={'mBackground'}>
                                            <p>M</p>
                                        </div>
                                        <div>
                                            <p>Vally</p>
                                            <p>10th September 2023</p>
                                        </div>
                                    </div>
                                </figcaption>
                            </figure>
                        </li>
                        <li className={'animate__animated animate__fadeIn animate__delay-2s'}>
                            <figure className={'smallBlogCard'}>
                                <img src={Interview} alt='listening' />
                                <figcaption>
                                    <div className={'smallCardTop'}>
                                        <p className={'bgHolder'}>Minnesota records</p>
                                        <p>5 min read</p>
                                    </div>
                                    <h4>An interesting interview sessions with Nelson Jay about his hot single Doosin</h4>
                                    <div className={'smallCardBtm'}>
                                        <div className={'mBackground'}>
                                            <p>M</p>
                                        </div>
                                        <div>
                                            <p>Nelson</p>
                                            <p>7th September 2023</p>
                                        </div>
                                    </div>
                                </figcaption>
                            </figure>
                        </li>
                        <li className={'animate__animated animate__fadeIn animate__delay-3s'}>
                            <figure className={'smallBlogCard'}>
                                <img src={Hustling} alt='listening' />
                                <figcaption>
                                    <div className={'smallCardTop'}>
                                        <p className={'bgHolder'}>Minnesota records</p>
                                        <p>5 min read</p>
                                    </div>
                                    <h4>Jerry Berlin just dropped a hot single Hustle and it's taking over the streets</h4>
                                    <div className={'smallCardBtm'}>
                                        <div className={'mBackground'}>
                                            <p>M</p>
                                        </div>
                                        <div>
                                            <p>Berlin</p>
                                            <p>14th September 2023</p>
                                        </div>
                                    </div>
                                </figcaption>
                            </figure>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default Blogs
import React from 'react'
import { Logo } from '../assets/images'
import { BsFacebook, BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import Text from '../utils/CustomText'

const Footer = () => {
    return (
        <footer className={'footerContainer'}>
            <div>
                <img src={Logo} alt='logo' />
                <ul className={'flexRow alignCenter'} style={{ marginTop: '10px' }}>

                    <li onClick={() => window.open('https://twitter.com/minnesota455', '_blank', 'noopener')}>
                        <BsTwitter color='#fff' />
                    </li>
                    <li onClick={() => window.open('https://web.facebook.com/profile.php?id=100095358873793', '_blank', 'noopener')}>
                        <BsFacebook />
                    </li>
                    <li onClick={() => window.open('https://www.instagram.com/sota.records/', '_blank', 'noopener')}>
                        <BsInstagram />
                    </li>
                    <li onClick={() => window.open('https://youtube.com/@sota.records?si=srWUzlK95cQZ0lRK', '_blank', 'noopener')}>
                        <BsYoutube />
                    </li>
                </ul>
            </div>
            <div>
                <ul className={'flexColumn'}>
                    <li>Quick Links</li>
                    <li>
                        <Link to='/artists' >
                            <Text tag={'p'} className={'f16 boldText'}
                                style={{ color: '#fff' }}
                            >
                                Artists
                            </Text>
                        </Link>
                    </li>
                    <li>
                        <Link to='/careers' >
                            <Text tag={'p'} className={'f16 boldText'}
                                style={{ color: '#fff' }}
                            >

                                Careers
                            </Text>
                        </Link>
                    </li>
                    <li>
                        <Link to='/tours' >
                            <Text tag={'p'} className={'f16 boldText'}
                                style={{ color: '#fff' }}
                            >

                                Tours
                            </Text>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/blog'}>
                            <Text tag={'p'} className={'f16 boldText'}
                                style={{ color: '#fff' }}
                            >
                                Blog
                            </Text>
                        </Link>

                    </li>
                    <li>
                        <Link to={'/contact'}>
                            <Text tag={'p'} className={'f16 semiBoldText'}
                                style={{ color: '#fff' }}
                            >Contact us</Text>
                        </Link>
                    </li>

                </ul>
            </div>
            {/* <div>
                <ul className={'flexColumn'}>
                    <li>Other Links</li>
                    <li>Partnership</li>
                    <li>Media</li>
                    <li>Video Production</li>
                </ul>
            </div> */}
            {/* <div>
                <p style={{ color: '#e4b848', marginBottom: '10px' }}>Address</p>
                <address style={{ maxWidth: '300px' }}>
                    No 22, Avenue block Oloko Street, Lagos, Nigeria
                    P0 BOX 121232 Landmark James Brown Boulevard
                </address>
            </div> */}
            <div>
                <ul className={'flexColumn'}>
                    <li>Contact</li>
                    <li>Email: minnesota.recordz@gmail.com</li>
                    <li>Phone: +234 812 031 8748</li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer

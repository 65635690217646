import React from 'react'
import { Brand1, Brand2, Brand3, Brand4, Brand5, Brand6, Brand7, Brand8, Brand9 } from '../../assets/images'




const Brands = (props) => {
    return (
        <section className={'brandsSection'} id={props.id}>
            <header >
                <div>
                    <h2>Brands we work with</h2>
                    <p>Over the years we've partnered with a lot of brands, these are some of our partnership. We are trusted by over 50+ brands.</p>
                </div>
            </header>
            <ul className={'flexRow alignCenter'}>
                <li>
                    <img src={Brand1} alt='brand1' />
                </li>
                <li>
                    <img src={Brand2} alt='brand2' />
                </li>
                <li>
                    <img src={Brand3} alt='brand3' />
                </li>
                <li>
                    <img src={Brand4} alt='brand4' />
                </li>
                <li>
                    <img src={Brand5} alt='brand5' />
                </li>
                <li>
                    <img src={Brand6} alt='brand6' />
                </li>
                <li>
                    <img src={Brand7} alt='brand7' />
                </li>
                <li>
                    <img src={Brand8} alt='brand8' />
                </li>
                <li>
                    <img src={Brand9} alt='brand9' />
                </li>
            </ul>
        </section>
    )
}

export default Brands
import React from 'react'
import Navbar from '../../widgets/Navbar'
import { Mail, Phone } from '../../assets/images'

const Contact = () => {
    return (
        <>
            <Navbar />
            <section className={'contactContainer'}>
                <header>
                    <h2>Contact us</h2>
                    <p>
                        Do you want to book any of our artists for a show or a project?
                        Fill out the form or send us an email and we will get back to you as soon as possible.
                    </p>
                </header>
                <div>
                    <ul>
                        <li>
                            <a href="tel:+2348120318748">
                                <div>
                                    <img src={Phone} alt='phone' />
                                </div>
                                <p>Call us today</p>
                                <p>+234 812 031 8748</p>
                            </a>

                        </li>
                        <li>
                            <a href="mailto:minnesota.recordz@gmail.com">
                                <div>
                                    <img src={Mail} alt='mail' />
                                </div>
                                <p>Send an email</p>
                                <p>minnesota.recordz@gmail.com</p>
                            </a>

                        </li>
                    </ul>
                    <div className={'contactFormContainer'}>
                        <input placeholder='Full Name' />
                        <input placeholder='Email' />
                        <textarea placeholder='Message...'></textarea>
                        <button>Send</button>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Contact


// import React, { useRef } from 'react';
// import emailjs from '@emailjs/browser';

// export const ContactUs = () => {
//     const form = useRef();

//     const sendEmail = (e) => {
//         e.preventDefault();

//         emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
//             .then((result) => {
//                 console.log(result.text);
//             }, (error) => {
//                 console.log(error.text);
//             });
//     };

//     return (
//         <form ref={form} onSubmit={sendEmail}>
//             <label>Name</label>
//             <input type="text" name="user_name" />
//             <label>Email</label>
//             <input type="email" name="user_email" />
//             <label>Message</label>
//             <textarea name="message" />
//             <input type="submit" value="Send" />
//         </form>
//     );
// };

// service_lnjh5hr
import React from 'react'
import Navbar from '../../widgets/Navbar'
import { useLocation} from 'react-router-dom';
import { Berlin, Doosin, Hustle, Nelson, Vally, Vibration } from '../../assets/images';
import { FaFacebook, FaInstagram, FaSpotify, FaTwitter } from 'react-icons/fa';
import { BsPlayFill } from 'react-icons/bs';
import { SiAudiomack } from 'react-icons/si';


const ArtistsDetails = () => {
    const location = useLocation();
    const artistInfo = location.state.artistInfo
    console.log(artistInfo)
    // const navigate = useNavigate()

    const returnHeader = () => {
        if (artistInfo === 'Nelson') {
            return <header>
                <h2>Nelson</h2>
                <img src={Nelson} alt='nelson' />
            </header>
        }
        if (artistInfo === 'Vally') {
            return <header>
                <h2>Vally</h2>
                <img src={Vally} alt='vally' />
            </header>
        }
        if (artistInfo === 'Berlin') {
            return <header>
                <h2>Berlin</h2>
                <img src={Berlin} alt='berlin' />
            </header>
        }
    }

    const returnDetails = () => {
        if (artistInfo === 'Nelson') {
            return <article>
                <p>
                    Jideofor Nelson Kosisochukwu Francis popularly known as Nelson Jay [Born 18th September 2006] is a Nigerian singer, songwriter and performer known for his unique style of Afrobeats music.
                    He was discovered by Minnesota label boss Leonard Warner in 2023 and subsequently signed to the label.
                    He grew up in Enugu State and also finished his secondary school Academy in Enugu at Kings High School.
                    Nelson Jay released his first single “Doosin” under Minnesota Records which is currently hitting everywhere.
                </p>
            </article>
        }
        if (artistInfo === 'Vally') {
            return <article>
                <p>
                    Vally was signed to Minnesota Records on July 2023 with a hot single “Vibration“.
                </p>
            </article>
        }
        if (artistInfo === 'Berlin') {
            return <article>
                <p>
                    Nwandu Etochukwu Jerryberlin is a Nigerian. [Born 29th July 2004]. He specializes in Afro-pop, Afro-melo, Hiphop and R$B genre, he is a songwriter and singer whose love for music started as a 10 year-old. He also plays instruments such as Guitar and vocals.
                    Jerryberlin joined choir at 11 years , he started with composing Gospel before he entered high school and got influenced by school music class and fell in love with sia and R&B
                    He Officially started his music career in 2019.
                    Jerry grew up in Udi, Enugu state. Finished his primary and secondary sections at Madonna nursery and primary school, Amokwe Udi.  His currently furthering his university education at Enugu State University Of Science and Technology(ESUT).
                    Jerry Berlin was signed to Minnesota Records on July 2023 with a hot single “Hustle “.
                </p>
            </article>
        }
    }

    const returnSocials = () => {
        if (artistInfo === 'Nelson') {
            return <ul>
                <li onClick={() => window.open('https://open.spotify.com/artist/3MvwayTy0NT2tJ3tb4l5qg', '_blank', 'noopener')}>
                    <FaSpotify />
                </li>
                <li onClick={() => window.open('https://www.instagram.com/nel_son_jay/', '_blank', 'noopener')}>
                    <FaInstagram />
                </li>
            </ul>
        }
        if (artistInfo === 'Vally') {
            return <ul>
                <li onClick={() => window.open('https://open.spotify.com/artist/3IEWwreW5P6aeDT0qI5IfI', '_blank', 'noopener')}>
                    <FaSpotify />
                </li>
                <li onClick={() => window.open('https://www.instagram.com/vally_szn/?fbclid=IwAR0amU6W1Iq0nk5yhwnDxWHUV0NHJ4qk8psQzpkgfzBCMCzx5zLqeo5JUAw', '_blank', 'noopener')}>
                    <FaTwitter />
                </li>
                <li onClick={() => window.open('https://www.instagram.com/vally_szn/', '_blank', 'noopener')}>
                    <FaInstagram />
                </li>
            </ul>
        }
        if (artistInfo === 'Berlin') {
            return <ul>
                <li onClick={() => window.open('https://web.facebook.com/profile.php?id=100091382538438', '_blank', 'noopener')}>
                    <FaFacebook />
                </li>
                <li onClick={() => window.open('https://open.spotify.com/artist/4GXkcG3n0KnDvJPmlY3fKT', '_blank', 'noopener')}>
                    <FaSpotify />
                </li>
                <li onClick={() => window.open('https://www.instagram.com/jerry_berlin_/', '_blank', 'noopener')}>
                    <FaInstagram />
                </li>
                <li onClick={() => window.open('https://audiomack.com/jerry_berlin_/', '_blank', 'noopener')}>
                    <SiAudiomack />
                </li>
            </ul>
        }
    }

    const returnLatestSingle = () => {

        if (artistInfo === 'Nelson') {
            return (
                <div className={'latestSingle'}>
                    <h4>Latest Single</h4>
                    <img src={Doosin} alt='doosin' />
                    <button className={'flexRow alignCenter'}
                        onClick={() => window.open('https://nelsonjay.fanlink.to/Doosin', '_blank', 'noopener')}
                    >
                        Play
                        <BsPlayFill size={30} />
                    </button>
                </div>
            )
        }
        if (artistInfo === 'Vally') {
            return (
                <div className={'latestSingle'}>
                    <h4>Latest Single</h4>
                    <img src={Vibration} alt='doosin' />
                    <button className={'flexRow alignCenter'}
                        onClick={() => window.open('https://vally.fanlink.to/Vibration', '_blank', 'noopener')}
                    >
                        Play
                        <BsPlayFill size={30} />
                    </button>
                </div>
            )
        }
        if (artistInfo === 'Berlin') {
            return (
                <div className={'latestSingle'}>
                    <h4>Latest Single</h4>
                    <img src={Hustle} alt='doosin' />
                    <button className={'flexRow alignCenter'}
                        onClick={() => window.open('https://jerryberlin.fanlink.to/Hustle', '_blank', 'noopener')}
                    >
                        Play
                        <BsPlayFill size={30} />
                    </button>
                </div>
            )
        }
    }


    return (
        <>
            <Navbar heroSectionId='#artist' />
            <section className={'artistDetailsContainer'} id='artist'>

                {/* <div className='backBtn' onClick={() => navigate(-1)}>
                    <BiArrowBack />
                    Back
                </div> */}
                {returnHeader()}
                {returnDetails()}
                {returnLatestSingle()}
                <div>
                    <h4>Follow on:</h4>
                    {returnSocials()}
                </div>
            </section>
        </>

    )
}

export default ArtistsDetails
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LandingPage from '../pages/home/LandingPage'
import Artists from '../pages/artists/Artists'
import ArtistsDetails from '../pages/artists/ArtistsDetails'
import Blogs from '../pages/blog/Blogs'
import Contact from '../pages/contact/Contact'
import Careers from '../pages/careers/Careers'
import Tours from '../pages/tours/Tours'


const RoutesContainer = () => {

    return (
        <>
            <Routes>
                <Route path='/' element={<LandingPage />} />
                <Route path='/artists' element={<Artists />} />
                <Route path='/artistDetails' element={<ArtistsDetails />} />
                <Route path='/blog' element={<Blogs />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/careers' element={<Careers />} />
                <Route path='/tours' element={<Tours />} />




            </Routes>
        </>
    )
}

export default RoutesContainer
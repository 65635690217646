import React from 'react'
import Navbar from '../../widgets/Navbar'

const Careers = () => {
    return (
        <>
            <Navbar />
            <section className={'careersContainer'}>
                <header>
                    <h2>
                        CAREERS
                    </h2>
                    <p>
                        Explore our career opportunities.
                    </p>
                    <p>
                        Coming Soon...
                    </p>
                </header>
            </section>
        </>
    )
}

export default Careers
import React, { useEffect, useState } from 'react';
import { BiMenu } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import { useMediaQuery } from 'react-responsive';
import Text from '../utils/CustomText';
import Logo from '../assets/images/logoNoBg.png';
import { Link } from 'react-router-dom';


const Navbar = (props) => {
    const [mobileNav, setMobileNav] = useState(false)
    const toggleMobileNav = () => {
        setMobileNav(previousState => !previousState);
    }
    const { openAuth } = props

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    // const toggleAuth = () => {
    //     toggleMobileNav()
    //     openAuth()
    // }

    const [navbarBackground, setNavbarBackground] = useState('inherit');

    useEffect(() => {
        // Function to update the navbar background color based on scroll position
        const handleScroll = () => {
            // const heroSection = document.querySelector(heroSectionId);
            // if (heroSection) {
            // const heroSectionHeight = heroSection.offsetHeight;
            if (window.scrollY > 200) {
                setNavbarBackground('rgba(105, 91, 54, 0.8'); // Change this to your desired background color
            } else {
                setNavbarBackground('inherit'); // Change this to your initial background color
            }
            // } else {
            //     console.log(heroSection)
            // }
        };

        // Add the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Remove the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>

            <nav className={'flexRow justifyBetween alignCenter navContainer stickBg'} style={{ background: navbarBackground }}>
                <section >
                    <Link to={'/'}>
                        <img src={Logo} alt='LOGO' />
                    </Link>
                </section>
                {isDesktopOrLaptop ?
                    <ul className={'flexRow justifyBetween alignCenter'}>
                        <li>
                            <Link to='/artists' >
                                <Text tag={'p'} className={'f16 boldText'}
                                    style={{ color: '#e4b848' }}
                                >
                                    Artists
                                </Text>
                            </Link>
                        </li>
                        <li>
                            <Link to='/careers' >
                                <Text tag={'p'} className={'f16 boldText'}
                                    style={{ color: '#e4b848' }}
                                >

                                    Careers
                                </Text>
                            </Link>
                        </li>
                        <li>
                            <Link to='/tours' >
                                <Text tag={'p'} className={'f16 boldText'}
                                    style={{ color: '#e4b848' }}
                                >

                                    Tours
                                </Text>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/blog'}>
                                <Text tag={'p'} className={'f16 boldText'}
                                    style={{ color: '#e4b848' }}
                                >
                                    Blog
                                </Text>
                            </Link>

                        </li>
                        <li onClick={openAuth}>
                            <Link to={'/contact'}>
                                <Text tag={'p'} className={'f16 semiBoldText'}
                                    style={{ color: ' #e4b848', border: '1px solid #e4b848', borderRadius: '8px', padding: '12px 16px', cursor: 'pointer' }}
                                >Contact us</Text>
                            </Link>

                        </li>
                    </ul> :
                    <button className='callButton'>
                        <BiMenu size={33} onClick={toggleMobileNav} color={'#e4b848'} style={{ marginTop: "10px" }} />
                    </button>
                }
                {mobileNav &&

                    <ul className={'flexColumn mobileNav'}>
                        {/* <button className='mobileCallButton'> */}
                        <AiOutlineClose size={33} onClick={toggleMobileNav} color={'#e4b848'} className={'mobileCallButton'} />
                        {/* </button> */}
                        <div style={{ marginTop: '50px' }} />

                        <li>
                            <Link to='/artists' >
                                <Text tag={'p'} className={'f16 boldText'}
                                    style={{ color: '#e4b848' }}
                                >
                                    Artists
                                </Text>
                            </Link>
                        </li>
                        <li>
                            <Link to='/careers' >
                                <Text tag={'p'} className={'f16 boldText'}
                                    style={{ color: '#e4b848' }}
                                >

                                    Careers
                                </Text>
                            </Link>
                        </li>
                        <li>
                            <Link to='/tours' >
                                <Text tag={'p'} className={'f16 boldText'}
                                    style={{ color: '#e4b848' }}
                                >

                                    Tours
                                </Text>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/blog'}>
                                <Text tag={'p'} className={'f16 boldText'}
                                    style={{ color: '#e4b848' }}
                                >
                                    Blog
                                </Text>
                            </Link>

                        </li>
                        <li onClick={openAuth}>
                            <Link to={'/contact'}>
                                <Text tag={'p'} className={'f16 semiBoldText'}
                                    style={{ color: ' #e4b848', border: '1px solid #e4b848', borderRadius: '8px', padding: '12px 16px', cursor: 'pointer' }}
                                >Contact us</Text>
                            </Link>

                        </li>
                    </ul>}
            </nav>
        </>
    )
}

export default Navbar
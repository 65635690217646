import React from 'react';
import Navbar from '../../widgets/Navbar';
import { Berlin, Nelson, Vally } from '../../assets/images';
import { useNavigate } from 'react-router-dom';

const Artists = () => {
    const navigate = useNavigate()
    const artistDetails = (val) => {
        navigate('/artistDetails', { state: { artistInfo: val } });
    }

    return (
        <>
            <Navbar />
            <section className={'aboutContainer '} >
                <header>
                    <h2 className={'animate__animated animate__fadeIn'}>Artists</h2>
                </header>
                <ul>
                    <li className={'animate__animated animate__fadeIn'}>
                        <figure>
                            <img src={Nelson} alt='nelson' />
                            <figcaption onClick={() => artistDetails('Nelson')}>
                                <p>Nelson</p>
                            </figcaption>
                        </figure>
                    </li>
                    <li className={'animate__animated animate__fadeIn animate__delay-1s'}>
                        <figure>
                            <img src={Vally} alt='vally' />
                            <figcaption onClick={() => artistDetails('Vally')}>
                                <p>Vally</p>
                            </figcaption>
                        </figure>

                    </li>
                    <li className={'animate__animated animate__fadeIn animate__delay-2s'}>
                        <figure>
                            <img src={Berlin} alt='berlin' />
                            <figcaption onClick={() => artistDetails('Berlin')}>
                                <p>Berlin</p>
                            </figcaption>
                        </figure>
                    </li>
                </ul>
            </section>
        </>
    )
}

export default Artists
import React from 'react'
import HeroSection from '../../components/home/HeroSection'
import Navbar from '../../widgets/Navbar'
import BestSection from '../../components/home/BestSection'
import Singles from '../../components/home/Singles'
import Brands from '../../components/home/Brands'
import Footer from '../../widgets/Footer'

const LandingPage = () => {
    return (
        <main>
            <Navbar heroSectionId='#hero' />
            <HeroSection />
            <BestSection id={'section1'} />
            <Singles id={'section2'} />
            {/* <Artistes id={'section3'} /> */}
            <Brands id={'section4'} />
            <Footer />
        </main>
    )
}

export default LandingPage